import styled from '@emotion/styled'

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  @media screen and (min-width: 740px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }
`

export default Container
