import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Card from '../components/Card'
import Container from '../components/Container'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`fivemost`, `products`, `reviews`]}
          image="/cover-1582720324.jpg"
        />
        <Container>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <Card
                link={node.fields.slug}
                date={node.frontmatter.date}
                title={title}
                cover={node.frontmatter.cover}
                excerpt={node.excerpt}
                category={node.frontmatter.category}
                mb={5}
              />
            )
          })}
        </Container>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            cover
            category
          }
        }
      }
    }
  }
`
