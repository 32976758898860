import React from 'react'
import { Link } from 'gatsby'
import { Box, Text, Flex, Badge } from '@chakra-ui/core'
import Image from './Image'

const Card = ({ category, title, cover, excerpt, date, link, ...rest }) => (
  <Link style={{ boxShadow: `none` }} to={link}>
    <Box
      maxW={['100%', '400px']}
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      {...rest}
    >
      <Image aspectRatio={1.5} src={cover} sizes={[194, 388, 776, 800]} />
      <Box p="2">
        {/* <Flex align="baseline" mt={2}>
          <small>{date}</small>
        </Flex> */}
        <Text
          mt={2}
          fontSize="xl"
          fontWeight="semibold"
          lineHeight="short"
          as="h1"
        >
          {title}
        </Text>

        <Text mt={2}>
          <p dangerouslySetInnerHTML={{ __html: excerpt }} />
        </Text>
        <Flex align="baseline" mt={2}>
          {category.map(cat => (
            <Badge variantColor="yellow" mr={2} py={1} px={2}>
              {cat}
            </Badge>
          ))}
        </Flex>
      </Box>
    </Box>
  </Link>
)

export default Card
